import * as React from "react"
import Container from "../../atoms/Container"
import PricingTable, { PricingTableProps } from "../../organisms/PricingTable"

const PricingTableSection = (props: PricingTableProps) => (
  <Container>
    <PricingTable {...props} />
  </Container>
)

export default PricingTableSection
