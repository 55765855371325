import React from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import Button from "../../atoms/Button"

const StyledContainer = styled(Container)`
  &.callout p {
    font-size: 1.75rem;
    line-height: 1.4;
  }
`

const TextSection = ({
                       alignment = "left",
                       children = null,
                       className = "",
                       buttonText = "",
                       buttonUrl = {},
                       bottomMargin = "default"
                     }) => {
  const buttonType = {
    secondary: className !== "callout",
    primary: className === "callout"
  }

  let bottomMarginMultiplier
  switch (bottomMargin) {
    case "xs":
      bottomMarginMultiplier = 4
      break
    case "sm":
      bottomMarginMultiplier = 2
      break
    case "default":
    default:
      bottomMarginMultiplier = 0
      break
  }

  const spacingProps = {
    removeVerticalSpacing: bottomMarginMultiplier,
    containerProps: {
      style: {
        marginBottom: bottomMarginMultiplier ? `calc(var(--section-spacing) / ${bottomMarginMultiplier}) ` : 0
      }
    }
  }

  return (
    <StyledContainer className={className} small {...spacingProps} innerProps={{ style: { textAlign: alignment } }}>
      {children}
      {buttonText && buttonUrl &&
      <Button to={buttonUrl?.url} target={buttonUrl?.target} {...buttonType}>{buttonText}</Button>
      // <Button className={"hs-cta-trigger-button hs-cta-trigger-button-132946483517"} to={buttonUrl?.url} target={buttonUrl?.target} {...buttonType}>{buttonText}</Button>
      }
    </StyledContainer>
  )
}

export default TextSection
