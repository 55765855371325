import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"

const StyledContainer = styled(Container)`
  text-align: center;
`

const ImageSection = ({image, width = 'full'}) => (
  <StyledContainer fluid={width === 'full'} small={width === 'small'}>
    <GatsbyImage {...image} />
  </StyledContainer>
)

export default ImageSection
