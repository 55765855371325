import { query } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"
import BulletPoints from "../../molecules/BulletPoints"
import Image from "../../organisms/Image"

const StyledImgBullet = styled.div`

`

const StyledBulletPoints = styled(BulletPoints)`
  //width: 100%;
  @media ${query({ from: "md" })} {
    //width: auto;
    float: right;
  }
`

const ImageWithBulletPointsSection = ({ image, items, heading }) => {
  return (
    <Container fluid>
      <Image showOffset={false} gatsbyImageProps={image} style={{zIndex: 3}} />
      <Container removeVerticalSpacing={true}>
        <div style={{ position: "relative" }}>
          <StyledBulletPoints heading={heading} items={items} imageAspect={image.aspectRatio} />
        </div>
        <div style={{ clear: "both" }} />
      </Container>
    </Container>
  )
}

export default ImageWithBulletPointsSection
