import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import SliceRenderer from "../slices"
import Container from "../components/atoms/Container"
import parse from "html-react-parser"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import PrismicFooter from "../layout/PrismicFooter"
import PrevNext from "../components/molecules/PrevNext"

const CaseStudy = ({ data, pageContext: { previous = {}, next = {} } }) => {
  const intro = data?.prismicCaseStudy?.data?.intro_text?.html
  const companyName = data?.prismicCaseStudy?.data?.company_name

  return (
    <Layout>
      <PrismicNavigation />

      {intro &&
        <Container innerProps={{ style: { textAlign: "center" } }}>
          <h4 style={{ fontWeight: "normal" }}>{companyName}</h4>
          <h1>{data.prismicCaseStudy.data.title}</h1>
          {parse(intro)}
        </Container>
      }
      <SliceRenderer doc={data?.prismicCaseStudy} />

      <PrevNext
        previous={{
          title: previous?.data?.title,
          url: previous?.uid && "/case-studies/" + previous?.uid
        }}
        next={{
          title: next?.data?.title,
          url: next?.uid && "/case-studies/" + next?.uid
        }}
      />

      <PrismicFooter />
    </Layout>
  )

}

export const query = graphql`
    query singleCaseStudy($id: String){
        prismicCaseStudy(id: {eq: $id}) {
            __typename
            _previewable
            data {
                slug_prefix
                meta_title
                meta_description
                og_image{
                    url
                    gatsbyImageData(layout: FIXED, width: 1200, height: 627)
                }
                title
                company_name
                intro_text{
                    html
                }
                body{
                    ...CaseStudyImageWithBulletPoints
                    ...CaseStudyText
                    ...CaseStudyImage
                    ...CaseStudyVideo
                    ...CaseStudyTestimonials
                    ...CaseStudyRoadsignCallout
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

CaseStudy.query = query

export default withPrismicPreview(CaseStudy)
