import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import * as React from "react"
import Text from "../../components/sections/TextSection"

const PrismicInsightDataBodyText = ({ primary }) => {
  const { button_url = {}, button_text = "", text = {}, style = "normal", alignment } = primary
  return (
    <Text
      alignment={alignment}
      buttonUrl={button_url}
      buttonText={button_text}
      className={style.toLowerCase()}
    >
      <PrismicRichText field={text?.richText} />
    </Text>
  )
}
export default PrismicInsightDataBodyText

export const query = graphql`
    fragment InsightText on PrismicInsightDataBodyText{
        slice_type
        slice_label
        __typename
        primary{
            text{
                html
                richText
            }
            button_url{
                url
                target
            }
            button_text
            style
            alignment
        }
    }
`
