import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import { query } from "atomic-layout"
import Image from "../../organisms/Image"

const StyleWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin: calc(${gap} / -2);
  justify-content: center;
`

const StyledContainer = styled.div`
  padding: calc(${gap} / 2);
  width: 100%;
  display: flex;
  align-items: stretch;
  
  @media ${query({from: 'md'})}{
    width: 50%;
  }
  @media ${query({from: 'lg'})}{
    width: 33.333%;
  }
`
const StyledBackground = styled.div`
  background-color: #fff;
  box-shadow: 0px 2px 83px -30px rgba(0, 0, 0, 0.15);
  padding: 43px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .text{
    margin-top: auto;
    margin-bottom: auto;
    align-self: center;
  }
  .text *:first-child{
    margin-top: 0;
    flex: 1;
  }
  
  .gatsby-image-wrapper {
    width: 59px;
    margin-left: auto;
    margin-bottom: 34px;
  }
`

export type BoxProps = {
  text: React.ReactNode,
  image: GatsbyImageProps,
  id: string,
}

const ShadowBoxes = ({boxes}: {boxes: BoxProps[]}) => {
  return (
    <StyleWrapper>
      {boxes.map(({ image, text, id }, index) => (
        <StyledContainer key={id + index}>
          <StyledBackground>
            {image?.image && 
              <GatsbyImage {...image} />
            }
            <div className={'text'}>
            {text}
            </div>
          </StyledBackground>
        </StyledContainer>
      ))}
    </StyleWrapper>
  )
}

export default ShadowBoxes
