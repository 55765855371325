import { query } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"
import Offset from "../../atoms/Offset"

const StyledBulletPointsWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 2rem;
  width: 100%;
  
  @media ${query({from: 'md'})}{
    width: auto;
    margin-top: ${({marginTop}) => marginTop};  
  }
`

const StyledBulletPoints = styled.div`
  position: relative;
  z-index: 9;
  background-color: #322225;
  color: #fff;
  padding: 4rem 3rem;
  max-width: 500px;

  ${({ theme }) => theme.shadow}
  ul {
    padding-left: 1rem;
  }
  
  @media ${query({from: 'md'})}{
    padding: 4rem 7rem 4rem 3rem;
    ul {
      padding-left: 4rem;
    }
  }
`

const BulletPoints = ({ heading = null, items = [], style = {}, className = null, imageAspect = 0.5 }) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()

  return (
    <StyledBulletPointsWrapper marginTop={`calc(calc(100vw * ${imageAspect}) * -0.3)`} style={style} ref={ref} className={className}>
      <Offset direction={"right"} height={height} width={width} />
      <StyledBulletPoints>
        {heading}
        <ul>
          {items.map((item, index) => <li key={'bullet' + index}>{item}</li>)}
        </ul>
      </StyledBulletPoints>
    </StyledBulletPointsWrapper>
  )
}

export default BulletPoints
