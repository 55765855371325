import { Composition, query } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import { CSSProperties, useRef } from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button/index"
import Image from "../Image"

export interface ImageWithTextProps {
  buttonUrl?: object,
  buttonText?: string,
  image?: GatsbyImageProps,
  text?: React.ReactNode,
  heading?: React.ReactNode,
  imagePosition?: "Top" | "Left" | "Right",
  className?: string,
  style?: CSSProperties,
  logo?: GatsbyImageProps,
  showOffset?: boolean,
}

const areas = `
image
text
`

const StyledLogo = styled(GatsbyImage)`
  width: 100%;
  max-width: 50%;
  margin-bottom: 2rem;
  height: 10vw;
  max-height: 70px;
  
  @media ${query({from: 'lg'})}{
    max-width: 50%;
  }
`

const StyledText = styled.div`
  margin-bottom: var(--gutter);

  *:first-child {
    margin-top: 0;
  }
`

const StyledImage = styled(Image)`
  margin-bottom: 3rem;
  
  @media ${query({from: 'lg'})}{
    margin-bottom: 0;
  }
`

const StyledHeadingLink = styled(Button)`
  color: var(--body-text-color);
`

const ImageWithText = (
  {
    className = "",
    buttonUrl = {},
    buttonText = "",
    image = null,
    text = null,
    heading = null,
    imagePosition = "Top",
    style = {},
    logo = null,
    showOffset = true
  }: ImageWithTextProps
) => {
  const imageRef = useRef(null)

  let lgProps = {
    areasLg: `
            image
            text
        `,
    templateColsLg: `1fr`,
    templateRows: `auto`,
    templateRowsLg: `auto 1fr`
  }

  switch (imagePosition) {
    case "Right":
      lgProps = {
        areasLg: `text gap image`,
        templateColsLg: `4fr 1fr 7fr`
      }
      break
    case "Left":
      lgProps = {
        areasLg: `image gap text`,
        templateColsLg: `7fr 1fr 4fr`
      }
      break
  }
  
  const imageComponent = image?.image && <StyledImage
    showOffset={imagePosition !== "Top"}
    direction={imagePosition === "Top" ? "left" : imagePosition.toLowerCase()}
    gatsbyImageProps={image}
  />

  return (
    <Composition
      areas={areas}
      {...lgProps}
      gap={image.image && gap}
      alignItems={imagePosition !== "Top" && "center"}
      className={className}
      style={style}
    >
      {(Areas) => (
        <>
          {image?.image &&
          <Areas.Image>
            {!buttonUrl?.url && imageComponent}
            
            {buttonUrl?.url &&
            <Button to={buttonUrl?.url}>{imageComponent}</Button>
            }
          </Areas.Image>
          }

          <Areas.Gap />

          <Areas.Text style={{ position: "relative" }}>
            {logo?.image &&
            <StyledLogo {...logo} imgStyle={{objectFit: "contain", objectPosition: "left center"}} />
            }
            {!buttonUrl?.url && heading}

            {buttonUrl?.url &&
            <StyledHeadingLink to={buttonUrl?.url}>{heading}</StyledHeadingLink>
            }
            <StyledText>
              {text}
            </StyledText>
            {buttonUrl && buttonText &&
            <Button to={buttonUrl?.url} target={buttonUrl?.target} secondary>{buttonText}</Button>
            }
          </Areas.Text>
        </>
      )}
    </Composition>
  )
}

export default ImageWithText
