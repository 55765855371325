import * as React from "react"
import { graphql } from "gatsby"
import TestimonialSection from "../../components/sections/TestimonialSection"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyTestimonials = ({ items }) => {
  const testimonials = items.map(({ text, name, person_title }) => {
    return {
      text: <PrismicRichText field={text?.richText} />,
      name,
      title: person_title
    }
  })

  return (
    <TestimonialSection testimonials={testimonials} />
  )
}
export default PrismicPageDataBodyTestimonials

export const query = graphql`
    fragment Testimonials on PrismicPageDataBodyTestimonials{
        slice_type
        slice_label
        __typename
        items{
            text{
                html
                richText
            }
            name
            person_title
        }
    }
`
