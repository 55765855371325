import * as React from "react"
import BaseForm from "./BaseForm"

export default () => (
  <BaseForm
    title={"Apply Today"}
    action={"https://usebasin.com/f/5038723d5f3a"}
    redirect={"/careers/thanks"}
    name={"Career Application"}
    render={({ handleChange }) => (
      <React.Fragment>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>First Name</div>
            <input type="text"
                   name="first-name"
                   onChange={handleChange}
                   placeholder={"First Name"}
                   required/>
          </label>
        </div>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>Last Name</div>
            <input type="text"
                   name="last-name"
                   onChange={handleChange}
                   placeholder={"Last Name"}
                   required/>
          </label>
        </div>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>Email</div>
            <input type="email"
                   name="email"
                   onChange={handleChange}
                   placeholder={"Email"}
                   required/>
          </label>
        </div>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>Phone</div>
            <input type="phone"
                   name="phone"
                   onChange={handleChange}
                   placeholder={"Phone Number"}
                   required/>
          </label>
        </div>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>Position</div>
            <input type="text"
                   name="position"
                   onChange={handleChange}
                   placeholder={"Position"}
                   required/>
          </label>
        </div>
        <div className={'form-group'}>
          <label>
            <div className={"label required"}>Cover letter & Resume</div>
            <input type="file" name="resume[]" onChange={handleChange} required multiple />
            <span style={{fontSize: 12, color: '#777777'}}>cmd or ctl click to select multiple files</span>
          </label>
        </div>
      </React.Fragment>
    )}
  />

)
