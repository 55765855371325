import { Composition } from "atomic-layout"
import { GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import { gap } from "../../../utils/spacing"
import Container from "../../atoms/Container"
import Image from "../../organisms/Image"

type Logo = {
  image?: GatsbyImageProps,
}

type Logos = {
  logos?: Logo[],
}

const LogoSection = ({ logos = [], title = null }) => (
  <Container>
    <div style={{marginBottom: 40}}>
    {title}
    </div>
    <Composition
      templateCols={'1fr 1fr'}
      templateColsMd={`repeat(${Math.max(logos.length, 4)}, 1fr)`}
      gap={gap}
    >
      {logos.map((logo, index) => (
        <Image key={"logo" + index} gatsbyImageProps={logo} showOffset={false} />
      ))
      }
    </Composition>
  </Container>
)

export default LogoSection
