import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import * as React from "react"
import RoadsignCallout from "../../components/sections/RoadsignCalloutSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyCallout = ({ primary }) => {
  const {
    button_url = {},
    button_text = "",
    heading = "",
    headingText = "",
    text = {},
    image = null
  } = primary

  return (
    <RoadsignCallout
      heading={headingText || heading}
      text={<PrismicRichText field={text?.richText} />}
      buttonText={button_text}
      buttonUrl={button_url}
      image={normalizeImageProps(image)}
    />
  )
}
export default PrismicPageDataBodyCallout

export const query = graphql`
    fragment RoadsignCallout on PrismicPageDataBodyRoadsignCallout{
        slice_type
        slice_label
        __typename
        primary{
            headingText: heading
            text{
                html
                richText
            }
            button_url{
                url
                target
            }
            button_text
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, height: 300)
            }
        }
    }
`
