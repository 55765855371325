import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import ListingSection from "../../components/sections/ListingSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyInsights = ({ primary, items }) => {
  return (
    <StaticQuery
      query={staticQuery}
      render={({ allPrismicInsight }) => {
        const { nodes } = allPrismicInsight

        const imageWithTextItems = nodes.map((item): ImageWithTextProps => {
          const { url, tags, data: { title, intro_text = {}, featured_image = null, thumbnail_image = null } } = item
          const image = thumbnail_image?.gatsbyImageData ? thumbnail_image : featured_image
          return {
            buttonUrl: { url },
            image: normalizeImageProps(image),
            text: parse(intro_text?.html),
            heading: <h5>{title}</h5>,
            tags,
          }
        })

        return (
          <ListingSection items={imageWithTextItems} />
        )
      }}
    />)
}
export default PrismicPageDataBodyInsights

export const staticQuery = graphql`query insightQuery {
  allPrismicInsight(sort: {fields: data___published_date, order: DESC}) {
    nodes {
      url
      tags
      data {
        title
        intro_text {
          html
        }
        featured_image {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            aspectRatio: 0.86
            imgixParams: {fit: "crop", crop: "faces"}
          )
        }
        thumbnail_image {
          alt
          gatsbyImageData(layout: CONSTRAINED, width: 688)
        }
      }
    }
  }
}`

export const query = graphql`
    fragment InsightsPage on PrismicPageDataBodyInsights{
        slice_type
        slice_label
        __typename
    }
`
