import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import ImageWithTextSection from "../../components/sections/ImageWithTextSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyCaseStudy = ({ primary, items }) => {
  const { heading, columns } = primary
  const cols = parseInt(columns.replace(" Column", ""))

  const imageWithTextItems = items.map((item, index): ImageWithTextProps => {
    const {
      case_studies: {
        url,
        document: {
          tags,
          data: {
            excerpt,
            featured_image,
            logo,
            title,
          }
        }
      }
    } = item

    let pos = "Top"
    if (cols === 1) {
      // If this is a single column design, then alternate the left and right image positions
      pos = index % 2 == 0 ? "Left" : "Right"
    }

    return {
      buttonText: "View Case Study",
      buttonUrl: {url},
      image: normalizeImageProps(featured_image),
      text: parse(excerpt?.html),
      heading: <h5>{tags.join(', ')}</h5>,
      imagePosition: pos,
      logo: normalizeImageProps(logo)
    }
  })

  return (
    <ImageWithTextSection
      heading={parse(heading?.html)}
      items={imageWithTextItems}
      columns={cols}
    />
  )
}
export default PrismicPageDataBodyCaseStudy

export const query = graphql`
    fragment CaseStudy on PrismicPageDataBodyCaseStudy {
        slice_label
        slice_type
        __typename
        items {
            case_studies {
                document {
                    ... on PrismicCaseStudy {
                        id
                        tags
                        data {
                            title
                            excerpt {
                                html
                            }
                            featured_image {
                                alt
                                gatsbyImageData(layout: CONSTRAINED, width: 800, aspectRatio: 0.86, imgixParams: {fit: "crop", crop: "faces"})
                            }
                            logo {
                                alt
                                gatsbyImageData(layout: CONSTRAINED, width: 400)
                            }
                        }
                    }
                }
                url
            }
        }
        primary {
            heading {
                html
            }
            columns
        }
    }
`
