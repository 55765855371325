import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { debounce } from "lodash"
import { readableColor } from "polished"
import * as React from "react"
import { useState } from "react"
import Helmet from "react-helmet"
import styled, { css } from "styled-components"
import logo from "../../../../static/images/logo.png"
import Button from "../../atoms/Button"
import Container from "../../atoms/Container"
import Link from "../../atoms/Link/index"

export interface NavigationLinks {
  link: React.ReactNode,
  sub: NavigationLinks[] | []
}

type NavigationProps = {
  lang?: string,
  collapseWidth?: string,
  menuItems?: NavigationLinks[],
  bannerUrl?: string,
  bannerText?: string,
  bannerColor?: string,
}

const StyledBanner = styled.div`
  &, a, a:visited, a:hover, a:active {
    color: ${props => props.color ? `${readableColor(props["color"])}` : "#000"};
  }

  z-index: 999;
  text-align: center;
  background-color: ${props => props.color ?? "#c7da7f"};
  font-weight: bold;
  padding: 0.5rem;
  position: fixed;
  max-width: 1440px;
  top: 0;
`

const StyledHeaderWrapper = styled.header`
  position: relative;
  top: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.90);

  @media (min-width: ${props => props.collapseWidth}) {
    @keyframes slideDown {
      from {
        top: -100px;
      }
      to {
        top: 0;
      }
    }
  }

  &.stuck {
    position: sticky;
    box-shadow: 0px 0px 5px 0px #00000030;
    animation-name: slideDown;
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    background-color: rgba(255, 255, 255, 0.90);

    .nav-wrapper {
      margin: 0;
    }

    .site-logo {
      img {
        width: 100%;
        height: auto;
        // max-width: ${({ stickHeader }) => stickHeader ? 160 : 230}px;
      }
    }
  }

  width: 100%;
`

const StyledHeader = styled(Container)`
  ${props => props.hasBanner && `
    margin-top: 48px;
  `}

  z-index: 999;
  width: 100%;
  backdrop-filter: blur(10px);
  padding: ${({ stickHeader }) => stickHeader ? 10 : 30}px 0;
  transition: 0.2s;
  
  a{
    cursor: pointer;
  }

  .nav-wrapper {
    display: flex;
    align-items: center;
    transition: 0.3s ease-out;
  }

  @media (max-width: ${props => props.collapseWidth}) {
    //position: fixed;
    //top: 0;
    padding: 15px 0;

    .nav-wrapper {
      height: auto;
      margin: 0;
    }
  }

  .nav {
    display: flex;
  }

  .nav-menu {
    flex-direction: column;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .above-menu {
    display: flex;
    align-self: flex-end;

    li {
      font-weight: normal;
      color: ${props => props.theme.secondaryColor};
    }

    a {
      color: ${props => props.theme.secondaryColor};
    }
  }

  .site-logo {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      transition: 0.3s;
      max-width: 160px;
      @media (min-width: ${props => props.collapseWidth}) {
        max-width: 220px;
      }
    }
  }

  .above-menu, .nav-list {
    display: none;
    @media (min-width: ${props => props.collapseWidth}) {
      display: flex;
    }
  }

  .above-menu ul {
    display: flex;
    margin-bottom: 0;

    li {
      position: relative;
      list-style: none;
      display: block;
      margin-right: 25px;
      line-height: 120%;
      white-space: nowrap;
      cursor: pointer;
      text-align: right;
    }
  }

  ${Button}, span {
    &:not(.btn) {
      color: var(--body-text-color);
      font-family: ${props => props.theme.headingFontFamily};
      text-decoration: none;
    }

  }

  .btn {
    margin-left: calc(48px - var(--menu-item-spacing));
    font-size: 1.4375rem;
  }

  ul.nav-list {
    text-align: right;
    padding: 0;
    margin-bottom: 0;
    align-self: flex-end;
    transition: .5s transform;
    font-size: 1.125rem;

    li {
      position: relative;
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      align-items: center;
      margin-right: var(--menu-item-spacing);
      white-space: nowrap;
      cursor: pointer;
      //line-height: 46px;

      &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        //margin-right: -0.25em; /* Adjusts for spacing */
      }

      :hover {

        ul {
          visibility: visible;
          pointer-events: auto;
          opacity: 1;
          margin-top: 0;

          a, span {
            padding-bottom: 10px;
            padding-right: 20px;
          }
        }
      }

      &:first-child {
        margin-left: 35px;
      }


      a.active:not(.btn), a:hover:not(.btn), span.active {
        color: #4DA893;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    // dropdown menu
    ul {
      display: flex;
      opacity: 0;
      margin-top: 10px;
      visibility: hidden;
      pointer-events: none;
      flex-direction: column;
      position: absolute;
      background: #fff;
      right: 0;
      //transform: translateY(50%);
      z-index: 9999;
      transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 1.125rem;

      li {
        padding: 0;
        margin: 0;

        &:before {
          display: none;
        }

        a {
          padding: 0;
          padding-top: 10px;
          padding-left: 10px;
          display: block;
          color: #000;
          transition: 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
        }
      }
    }
  }
`

const StyledNavContainer = styled.div`
  display: flex;
  width: 100%;

`

const StyledDrawer = styled.div`
  display: flex;
  @media (min-width: ${props => props.collapseWidth}) {
    display: none !important;
  }

  ${props => props.hasBanner && `
    margin-top: 48px;
  `}

  background: #fff;
  z-index: 9999;
  padding: 2rem 0;
  max-width: 80vw;
  min-width: 50vw;
  flex-direction: column;
  align-items: flex-end;
  //overflow-y: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.open {
    transform: translateX(-100vw);
    display: flex;
  }

  ul {
    display: flex; // Toggled State using the :checked pseudo-class
    flex-direction: column;
    text-align: right;
    padding: 0;
    width: 100%;
    //border-top: 1px solid #ebebeb;
    margin-top: 20px;

    li {
      list-style: none;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      color: ${props => props.theme.primaryColor};
      font-weight: bold;

      .label:after {
        content: " >";
      }

      ${Button}, span {
        &:not(.btn) {
          color: var(--body-text-color);
          font-family: ${props => props.theme.headingFontFamily};
          text-decoration: none;
          font-weight: normal;
        }

        &.btn {
          text-align: right;
        }
        &.active{
          color: #4DA893;
        }
      }
    }

    a, .label, span {
      display: block;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1.5rem;
    }

    li > ul {
      display: flex;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
      background: #fff;
      flex-direction: column;
      align-items: flex-end;
      overflow-y: scroll;
      right: -100%;
      transition: 0.3s;

      &.open {
        transform: translateX(-100%);
      }
    }
  }
`

const StyledNavToggle = styled.div<any>`
  display: block;

  @media (min-width: ${props => props.collapseWidth}) {
    display: none !important;
  }

  width: 25px;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;

  div {
    background-color: ${props => props.theme.primaryColor};
    width: 25px;
    height: 3px;
    margin-bottom: 4px;
    transition: transform 0.3s, opacity 0.3s;

    &:first-child {
      transform-origin: top left;
    }

    &:last-child {
      transform-origin: top left;
      margin-bottom: 0;
    }
  }

  &.open {
    div {
      &:first-child {
        transform: rotateZ(45deg) translate(0px, -1px);

      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        transform: rotateZ(-45deg) translate(-3px, 1px);
      }
    }
  }
`

class Navigation extends React.Component<NavigationProps, { menuOpen: boolean, stickHeader: boolean }> {

  private headerRef = React.createRef<HTMLDivElement>()
  private navListRef = React.createRef<HTMLUListElement>()
  private targetScrollLockElement = null
  private debouncedHandleScroll = null

  public constructor(props: NavigationProps) {
    super(props)
    this.state = {
      menuOpen: false,
      stickHeader: false,
    }

    this.debouncedHandleScroll = debounce(this.handleScroll.bind(this), 100)
    // this.handleScroll.bind(this)
  }

  public componentDidMount(): void {
    this.targetScrollLockElement = this.navListRef.current
  }

  public componentWillMount(): void {
    if (typeof document !== "undefined") {
      document.addEventListener("mousedown", this.handleClick.bind(this), false)
    }

    if (typeof window !== "undefined") {
      // window.addEventListener('resize', this.handleResize.bind(this))
      window.addEventListener("scroll", this.debouncedHandleScroll)
    }
  }

  public componentWillUnmount(): void {
    if (typeof document !== "undefined") {
      document.removeEventListener("mousedown", this.handleClick.bind(this), false)
    }

    if (typeof window !== "undefined") {
      // window.removeEventListener('resize', this.handleResize.bind(this))
      window.removeEventListener("scroll", this.debouncedHandleScroll)
    }
    clearAllBodyScrollLocks()
  }

  public handleScroll(e) {
    if(this.state.stickHeader){
      this.setState({ stickHeader: window.top.scrollY > 300 - this.headerRef?.current?.clientHeight })
    } else {
      this.setState({ stickHeader: window.top.scrollY > 300 })
    }
  }

  public handleClick(e: any) {
    if (this.state.menuOpen &&
      this.navListRef &&
      this.navListRef.current &&
      !this.navListRef.current.contains(e.target)
    ) {
      this.setState({ menuOpen: false })
      clearAllBodyScrollLocks()
    }
  }

  public toggleMenu() {
    const menuOpen = !this.state.menuOpen

    if (menuOpen) {
      disableBodyScroll(this.targetScrollLockElement)
    } else {
      enableBodyScroll(this.targetScrollLockElement)
    }

    this.setState({ menuOpen })
  }

  public renderMenuItem(menuItem: NavigationLinks, key: number) {
    const { link, sub = [] } = menuItem
    return (
      <li key={"menuItem" + (key++ + Math.random())}>
        {link}
        {sub.length > 0 && <ul>{sub.map(subMenuItem => this.renderMenuItem(subMenuItem, key))}</ul>}
      </li>
    )
  }

  public render() {
    const { menuOpen, stickHeader } = this.state
    const { menuItems = [], bannerText = null, bannerUrl = null, bannerColor = null } = this.props
    const hasBanner = bannerText && bannerUrl
    let key = 0

    return (
      <React.Fragment>
        {hasBanner &&
        <StyledBanner color={bannerColor} fluid>
          <Helmet
            bodyAttributes={{
              class: "has-banner"
            }}
          />
          <Link {...bannerUrl}>{bannerText}</Link>
        </StyledBanner>
        }
        <StyledHeaderWrapper ref={this.headerRef} className={stickHeader ? "stuck" : ""}>
          <StyledHeader
            removeVerticalSpacing={true}
            hasBanner={hasBanner}
            stickHeader={stickHeader}
          >
            <div className={"nav-wrapper"}>
              <StyledNavContainer>
                <div className="site-logo">
                  <Link to={"/"}>
                    <img width="220" height="58.09" src={logo} alt={"Logo"} />
                  </Link>
                </div>
                <div className={`nav-menu`}>
                  <StyledNavToggle
                    className={`${menuOpen ? " open" : ""}`}
                    onClick={() => this.toggleMenu()}
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                  </StyledNavToggle>

                  <ul className={`nav-list`}>
                    {menuItems.map((menuItem) => {
                      key++
                      return this.renderMenuItem(menuItem, key)
                    })}
                  </ul>

                </div>
              </StyledNavContainer>
            </div>
          </StyledHeader>
        </StyledHeaderWrapper>

        <StyledDrawer hasBanner={hasBanner} ref={this.navListRef} className={`${menuOpen ? " open" : ""}`}>
          <StyledNavToggle className={`${menuOpen ? " open" : ""}`} onClick={() => this.toggleMenu()}>
            <div></div>
            <div></div>
            <div></div>
          </StyledNavToggle>

          <MobileMenu menuItems={menuItems} isOpen={true} />

        </StyledDrawer>
      </React.Fragment>
    )
  }
}

const MobileMenu = ({ menuItems, isOpen = false, onBackClick = null }) => {

  return (
    <ul className={isOpen ? " open" : ""}>

      {typeof onBackClick === "function" &&
      <li style={{ padding: "1.5rem" }} onClick={onBackClick}>&lt; Back</li>
      }

      {menuItems.map((menuItem, index) => {
        const { link, sub = [] } = menuItem

        return <MenuLink key={"mobile" + index} link={link} sub={sub} />
      })}
    </ul>
  )
}

const MenuLink = ({ link, sub = [] }) => {
  const [isSubOpen, setIsSubOpen] = useState(false)

  return (
    <li
      onClick={sub.length ? (e) => setIsSubOpen(!isSubOpen) : () => null}
    >
      {link}
      {!!sub.length &&
      <MobileMenu
        menuItems={sub}
        isOpen={isSubOpen}
        onBackClick={() => setIsSubOpen(!isSubOpen)}
      />
      }
    </li>
  )
}

export const collapseWidth = "1000px"

StyledHeaderWrapper.defaultProps = {
  collapseWidth
}

StyledHeader.defaultProps = {
  collapseWidth
}

StyledNavToggle.defaultProps = {
  collapseWidth
}

StyledDrawer.defaultProps = {
  collapseWidth
}


export default Navigation
