import * as React from "react"
import styled from "styled-components"
import Container from "../../atoms/Container"
import ShadowBoxes, { BoxProps } from "../../molecules/ShadowBoxes"

const StyledHeading = styled.div`
  text-align: center;
`

const ShadowBoxSection = ({heading = null, boxes}: {heading: React.ReactNode, boxes: BoxProps[]}) => (
  <Container>
    <StyledHeading>{heading}</StyledHeading>
    <ShadowBoxes boxes={boxes} />
  </Container>
)

export default ShadowBoxSection
