import * as React from "react"
import { graphql } from "gatsby"
import Logos from '../../components/sections/LogoSection';
import parse from 'html-react-parser'
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyLogos = ({ primary, items }) => {
  const { heading = {} } = primary

  return (
    <Logos title={parse(heading?.html)} logos={items.map((item) => normalizeImageProps(item.logo))} />
  )
}
export default PrismicPageDataBodyLogos

export const query = graphql`
    fragment Logos on PrismicPageDataBodyLogos{
        slice_type
        slice_label
        __typename 
        primary{
            heading{
                html
            }
        }
        items{
            logo{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
        }
    }
`
