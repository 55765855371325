import React from "react"
import Container, { maxWidths } from "../../atoms/Container"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { gap } from "../../../utils/spacing"
import { Composition } from "atomic-layout"

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`

const IconTextSection = ({
                           items = [],
                           className = "",
                         }) => {

  return (
    <Container className={className} >
      {items.map(({ text, id, image }, index) => {
        return (
          <Composition
            {...maxWidths({fluid: false, small: true})}
            key={id + index}
            templateCols={`1fr`}
            templateColsMd={`129px 1fr`}
            gap={gap}
            marginHorizontal={'auto'}
            marginBottom={`calc(var(--section-spacing) / 2)`}
          >
            <div>
              <StyledImage {...image} />
            </div>
            <div>
              {text}
            </div>
          </Composition>
        )
      })}
    </Container>
  )
}

export default IconTextSection
