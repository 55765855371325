import { graphql } from "gatsby"
import * as React from "react"

const PrismicPageDataBodyAnchor = ({ primary }) => {
  const { name = "" } = primary
  return (
    <div id={name} />
  )
}
export default PrismicPageDataBodyAnchor

export const query = graphql`
    fragment Anchor on PrismicPageDataBodyAnchor{
        slice_type
        slice_label
        __typename
        primary{
            name
        }
    }
`
