import * as React from "react"
import { graphql } from "gatsby"
import Container from "../../components/atoms/Container"
import parse from "html-react-parser"

const PrismicInsightDataBodyHeading = ({ primary }) => {
  const { heading = {} } = primary
  return (
    <Container innerProps={{ marginBottom: "calc(3rem + calc(var(--section-spacing) * -1))" }} small>
      {parse(heading?.html)}
    </Container>
  )
}
export default PrismicInsightDataBodyHeading

export const query = graphql`
    fragment InsightHeading on PrismicInsightDataBodyHeading{
        slice_type
        slice_label
        __typename 
        primary{
            heading{
                html
            }
        }
    }
`
