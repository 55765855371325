import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import ImageWithTextSection from "../../components/sections/ImageWithTextSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyImageWithText = ({ primary, items }) => {
  const { cols, background_color } = primary

  const imageWithTextItems = items.map((item): ImageWithTextProps => {
    const { image, button_text, button_url, heading, image_position, image_text, text, logo } = item
    return {
      buttonText: button_text,
      buttonUrl: button_url,
      image: normalizeImageProps(image),
      text: parse(text?.html),
      heading: parse(heading?.html),
      imagePosition: image_position,
      logo: normalizeImageProps(logo)
    }
  })

  return (
    <ImageWithTextSection items={imageWithTextItems} backgroundColor={background_color} columns={cols.replace(" Column", "")} />
  )
}
export default PrismicPageDataBodyImageWithText

export const query = graphql`
    fragment ImageWithText on PrismicPageDataBodyImageWithText {
        slice_label
        slice_type
        __typename
        id
        primary{
            cols
            background_color
        }
        items {
            button_text
            button_url {
                url
                target
            }
            heading {
                html
            }
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 1200)
            }
            logo{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 500, imgixParams: {sat: -100})
            }
            image_position
            image_text
            text {
                html
            }
        }
    }
`
