import { Composition } from "atomic-layout"
import * as React from "react"
import { gap } from "../../../utils/spacing"
import Container from "../../atoms/Container"
import Background from "../../molecules/Background"
import ImageWithText, { ImageWithTextProps } from "../../organisms/ImageWithText"

type ImageWithTextSectionProps = {
  heading?: React.ReactNode,
  backgroundColor?: string,
  items?: ImageWithTextProps[],
  columns?: number,
  templateCols?: object,
}

const ImageWithTextSection = ({
                                heading = null,
                                backgroundColor,
                                items,
                                columns = 1,
                                templateCols = null
                              }: ImageWithTextSectionProps) => {
  const templateColProps = templateCols || {
    templateCols: "1fr",
    templateColsMd: `repeat(${columns}, minmax(0, 1fr))`
  }

  const isSmallContainer = columns === 1 && items[0].imagePosition === "Top"

  return (
    <Container background={backgroundColor && <Background color={backgroundColor} />} small={isSmallContainer}>
      <div style={{textAlign: 'center'}}>{heading}</div>
      <Composition
        {...templateColProps}
        gapRow={"var(--section-spacing)"}
        gapCol={gap}
      >
        {items.map((item, index) => {
          const { image, buttonText, buttonUrl, heading, imagePosition, text, logo } = item

          return (
            <ImageWithText
              key={"imgtext" + index}
              image={image}
              text={text}
              heading={heading}
              buttonText={buttonText}
              buttonUrl={buttonUrl}
              imagePosition={imagePosition}
              logo={logo}
              showOffset={columns === 1}
            />
          )
        })}
      </Composition>
    </Container>
  )
}

export default ImageWithTextSection
