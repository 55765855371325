import * as React from "react"
import CareerApplicationForm from "../../organisms/Forms/CareerApplicationForm"
import Container from "../../atoms/Container"

const FormsSection = ({ type }) => {
  let form = null
  switch (type) {
    case "Career Application":
      form = <CareerApplicationForm />
      break
    default:
      form = null
  }

  return <Container>
    {form}
  </Container>
}

export default FormsSection
