import { useEffect, useState } from "react"
import * as React from "react"

function cssVar(name,value?){
  if(name[0]!='-') name = '--'+name //allow passing with or without --
  if(value) document.documentElement.style.setProperty(name, value)
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

const Squiggles = ({svgProps = {}, componentRef = null, padding = 70}) => {
  const [height, setHeight] = useState(620)
  const [marginTop, setMarginTop] = useState(0)

  const setWidthHeight = () => {
    const ref = componentRef?.current?.imageRef?.current ?? componentRef?.current

    if (ref) {
      setHeight(ref?.offsetHeight + padding * 2)
    }
  }

  useEffect(() => {
    setWidthHeight()

    return () => {
      if(typeof window !== 'undefined') {
        window.removeEventListener("resize", setWidthHeight)
      }
    }
  }, [componentRef])

  if(typeof window !== 'undefined'){
    window.addEventListener("resize", setWidthHeight)
  }
  
  return (
    <div style={{marginTop: -padding - 40}}>
    <svg
      width={296}
      height={257 + height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d={`M-.5 10H173v41H37v${height}h64v44h184v47H183v43H60v62H-.5`}
        stroke="#F3F3F3"
        strokeWidth={20}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    </div>
  )
}

export default Squiggles
