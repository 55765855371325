import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
                children,
                to = null,
                activeClassName = "active",
                partiallyActive = false,
                target = "_self",
                ...other
              }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const href = to || other?.href || null

  const { className = "", style = {} } = other

  if(href === '#'){
    return <span style={style} className={className}>
      {children}
    </span>
  }

  const hubspotPopupLink = className.includes('hs-cta-trigger-button')

  const internal = href && (/^\/(?!\/)/.test(href) || href.startsWith(process.env.GATSBY_URL))
  // Use Gatsby Link for internal links, and <a> for others

  // We must include target !== '_blank' here, since Gatsby Link
  // doesn't work properly in iframes with blank target
  if (!hubspotPopupLink && internal && target !== '_blank') {
    return (
      <GatsbyLink
        to={href.replace(process.env.GATSBY_URL, '/')}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={className}
        style={style}
        target={target}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={hubspotPopupLink ? '#' : href} style={style} className={className} target={target}>
      {children}
    </a>
  )
}

export default Link
