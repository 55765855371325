import React, { useRef } from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { Composition, useResponsiveValue, query } from "atomic-layout"
import Button from "../../atoms/Button"
import Background from "../../molecules/Background"
import Squiggles from "../../atoms/Squiggles"
import { gap } from "../../../utils/spacing"
import Image from "../../organisms/Image"

const StyledContainer = styled(Container)`
  //overflow: hidden;
`

const StyledContent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const StyledTestomonial = styled.div`
  position: absolute;
  bottom: 0;
  background-color: #322225;
  padding: 42px;
  color: #fff;
  width: 80%;
  font-size: 18px;
  line-height: 150%;

  ${({ theme }) => theme.shadow}
  .testimonial__content {
    margin-bottom: 2rem;
  }

  .testimonial__name {
    color: #B2AAAB;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
  }

  .testimonial__title {
    color: #B2AAAB;
    font-size: 14px;
  }
`

const StyledBackground = styled(Background)`
  display: none;
  @media ${query({ from: 'lg' })} {
    display: block;
  }
`

const HeroSection = ({
                heading = null,
                image = {},
                buttonUrl,
                buttonText,
                text = {},
                testimonialContent = "",
                testimonialName = "",
                testimonialTitle = ""
              }) => {
  const contentRef = useRef(null)

  const marginTop = useResponsiveValue(
    {
      lg: 60,
      xl: 60,
    },
    100
  )

  const marginBottom = useResponsiveValue(
    {
      lg: '7rem',
      xl: '7rem',
    },
    '0rem'
  )

  return (
    <StyledContainer innerProps={{style: {marginTop: marginTop, marginBottom: `calc(var(--section-spacing) + ${marginBottom})`}}}>
      <Composition
        areas={`
        content
        `}
        templateCols={"1fr"}
        areasLg={`content gap image`}
        templateColsLg={"5fr 1fr 6fr"}
      >
        {(Areas) => (
          <>
            <Areas.Content as={StyledContent}>
              <div ref={contentRef} style={{ position: "relative" }}>
                <StyledBackground style={{ left: -80 }}>
                  <Squiggles componentRef={contentRef} />
                </StyledBackground>
                <div style={{position: 'relative'}}>
                {heading}
                {text}
                <Button to={buttonUrl?.url} target={buttonUrl?.target} primary>{buttonText}</Button>
                </div>
              </div>
            </Areas.Content>

            <Areas.Gap />

            <Areas.Image style={{ position: "relative" }}>
              <Composition
                areas={`testimonial image`}
                templateCols={"repeat(6, 1fr)"}
                gap={gap}
              >
                {(ImageAreas) => (
                  <>
                    <ImageAreas.Image colStart={2} colEnd={-1}>
                      {image.image &&
                      <Image
                        gatsbyImageProps={image}
                        direction={"right"}
                      />
                      }
                    </ImageAreas.Image>
                    <ImageAreas.Testimonial colStart={1} colEnd={5}>
                      <StyledTestomonial>
                        <div className={"testimonial__content"}>“{testimonialContent}”</div>
                        <div className={"testimonial__name"}>{testimonialName}</div>
                        <div className={"testimonial__title"}>{testimonialTitle}</div>
                      </StyledTestomonial>
                    </ImageAreas.Testimonial>
                  </>
                )}
              </Composition>
            </Areas.Image>
          </>
        )}
      </Composition>
    </StyledContainer>
  )
}

export default HeroSection
