import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import Callout from "../../components/sections/CalloutSection"

const PrismicPageDataBodyCallout = ({ primary }) => {
  const {
    primary_button_url = {},
    primary_button_text = "",
    secondary_button_url = {},
    secondary_button_text = "",
    heading = {},
    text = {}
  } = primary
  return (
    <Callout
      heading={parse(heading?.html)}
      text={<PrismicRichText field={text?.richText} />}
      primaryButtonText={primary_button_text}
      primaryButtonUrl={primary_button_url}
      secondaryButtonText={secondary_button_text}
      secondaryButtonUrl={secondary_button_url}
    />
  )
}
export default PrismicPageDataBodyCallout

export const query = graphql`
    fragment Callout on PrismicPageDataBodyCallout{
        slice_type
        slice_label
        __typename
        primary{
            heading{
                html
            }
            text{
                html
                richText
            }
            primary_button_url{
                url
                target
            }
            primary_button_text
            secondary_button_url{
                url
                target
            }
            secondary_button_text
        }
    }
`
