import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Footer from "../components/organisms/Footer"
// import InstagramSection from "../components/sections/InstagramSection"
import { normalizeImageProps } from "../utils/image"

const PrismicFooter = (props) => {
  return (
    <StaticQuery
      query={query}
      render={({ prismicFooter }) => {
        const { data = {} } = prismicFooter
        const {
          cta_heading = "",
          cta_text = "",
          links = [],
          logo = {}
        } = data

        return (
          <React.Fragment>
            {/*<InstagramSection />*/}
            <Footer
              links={links}
              ctaHeading={cta_heading}
              ctaText={cta_text}
              logo={normalizeImageProps(logo)}
            />
          </React.Fragment>
        )
      }}
    />

  )
}
export default PrismicFooter

export const query = graphql`
    query footerQuery {
        prismicFooter {
            data {
                cta_heading
                cta_text
                logo{
                    alt
                    gatsbyImageData(layout: CONSTRAINED, width: 775)
                }
                links {
                    label
                    link {
                        url
                    }
                }
            }
        }
    }
`
