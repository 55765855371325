import { graphql } from "gatsby"
import * as React from "react"
import ImageSection from "../../components/sections/ImageSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicCaseStudyDataBodyImage = ({ primary }) => {
  const { image = null, width = "full" } = primary
  const imageProps = normalizeImageProps(image)

  return (
    <ImageSection image={imageProps} width={width} />
  )
}

export default PrismicCaseStudyDataBodyImage

export const query = graphql`
    fragment CaseStudyImage on PrismicCaseStudyDataBodyImage{
        slice_type
        slice_label
        __typename
        primary{
            width
            image{
                alt
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`
