import  * as React from "react"
import { graphql, StaticQuery, Script } from "gatsby"
import { ThemeProvider } from "styled-components"
import Helmet from "react-helmet"
import { GlobalStyles } from "../theme/global"
import theme from "../theme"
import Seo from "../components/atoms/SEO"

const Layout = ({ children = null }) => {
  const query = graphql`
    query SiteTitleQuery {
      site {
        id
        siteMetadata {
          title
          description
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={data => (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Seo title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
          <Helmet>
            <Script strategy="idle" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js" />
            <meta name="facebook-domain-verification" content="pwm1qjzcokprdf84b7clmj5yio586m" />
            <link rel="preload" href="/fonts/greycliff-cf-regular.otf" as={"font"} crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/greycliff-cf-bold.otf" as={"font"} crossOrigin="anonymous" />
            <link href="/fonts.css" rel="stylesheet" />
          </Helmet>
          {children}
          {/*<ScrollTop/>*/}
          {/*<PrismicFooter />*/}
        </ThemeProvider>
      )}
    />
  )
}
export default Layout
