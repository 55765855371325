import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import ImageWithBulletPointsSection from "../../components/sections/ImageWithBulletPointsSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicCaseStudyDataBodyImageWithBulletPoints = ({ primary, items }) => {
  const { heading = {}, image = {} } = primary
  const imageProps = normalizeImageProps(image)

  return (
    <ImageWithBulletPointsSection
      image={imageProps}
      heading={parse(heading?.html)}
      items={items.map((item) => item.list_item)}
    />
  )
}

export default PrismicCaseStudyDataBodyImageWithBulletPoints

export const query = graphql`
  fragment CaseStudyImageWithBulletPoints on PrismicCaseStudyDataBodyImageWithBulletPoints {
    slice_label
    slice_type
    __typename
    id
    items {
      list_item
    }
    primary {
      heading {
        html
      }
      image {
        alt
        gatsbyImageData(layout: FULL_WIDTH, width: 1440)
      }
    }
  }
`
