import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../layout"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import SliceRenderer from "../slices"
import Container from "../components/atoms/Container"
import parse from "html-react-parser"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import PrismicFooter from "../layout/PrismicFooter"

const Page = (page) => {
  const intro = page?.data?.prismicPage?.data?.intro_text?.html
  const overlineSubtitle = page?.data?.prismicPage?.data?.overline_subtitle

  return (
    <Layout>
      <PrismicNavigation />

      {intro &&
        <Container innerProps={{ style: { textAlign: "center" } }}>
          {overlineSubtitle &&
            <h3 style={{ fontWeight: "normal" }}>{overlineSubtitle}</h3>
          }
          <h1>{page.data.prismicPage.data.title}</h1>
          {parse(intro)}
        </Container>
      }

      <SliceRenderer doc={page?.data?.prismicPage} />

      <PrismicFooter />
    </Layout>
  )

}

export const query = graphql`
    query singlePage($id: String){
        prismicPage(id: {eq: $id}) {
            __typename
            _previewable
            data {
                slug_prefix
                meta_title
                meta_description
                og_image{
                    url
                    gatsbyImageData(layout: FIXED, width: 1200, height: 627)
                }
                title
                overline_subtitle
                intro_text{
                    html
                }
                body{
                    ...Anchor
                    ...Hero
                    ...Text
                    ...ImageWithText
                    ...Video
                    ...Logos
                    ...Image
                    ...Testimonials
                    ...Person
                    ...CaseStudy
                    ...HubspotForm
                    ...Heading
                    ...HTML
                    ...Locations
                    ...ShadowBox
                    ...IconText
                    ...Callout
                    ...InsightsPage
                    ...FullBackgroundHero
                    ...PricingTable
                    ...ImageScroller
                    ...NonHubspotForm
                    ...RoadsignCallout
                }
            }
            uid
            id
            type
            lang
            alternate_languages {
                id
                lang
            }
        }
    }
`

Page.query = query

export default withPrismicPreview(Page)
