import PrismicPageDataBodyHero from './wrappers/PrismicPageDataBodyHero'
import PrismicPageDataBodyText from './wrappers/PrismicPageDataBodyText'
import PrismicPageDataBodyImageWithText from './wrappers/PrismicPageDataBodyImageWithText'
import PrismicPageDataBodyVideo from './wrappers/PrismicPageDataBodyVideo'
import PrismicPageDataBodyLogos from './wrappers/PrismicPageDataBodyLogos'
import PrismicPageDataBodyImage from './wrappers/PrismicPageDataBodyImage'
import PrismicPageDataBodyTestimonials from './wrappers/PrismicPageDataBodyTestimonials'
import PrismicPageDataBodyPerson from './wrappers/PrismicPageDataBodyPerson'
import PrismicPageDataBodyCaseStudy from './wrappers/PrismicPageDataBodyCaseStudy'
import PrismicPageDataBodyInsights from './wrappers/PrismicPageDataBodyInsights'
import PrismicPageDataBodyHubspotForm from './wrappers/PrismicPageDataBodyHubspotForm'
import PrismicPageDataBodyHeading from './wrappers/PrismicPageDataBodyHeading'
import PrismicPageDataBodyLocations from './wrappers/PrismicPageDataBodyLocations'
import PrismicPageDataBodyShadowBox from './wrappers/PrismicPageDataBodyShadowBox'
import PrismicPageDataBodyIconText from './wrappers/PrismicPageDataBodyIconText'
import PrismicPageDataBodyCallout from './wrappers/PrismicPageDataBodyCallout'
import PrismicPageDataBodyFullBackgroundHero from './wrappers/PrismicPageDataBodyFullBackgroundHero'
import PrismicPageDataBodyRawHtml from './wrappers/PrismicPageDataBodyRawHtml'
import PrismicPageDataBodyPricingTable from './wrappers/PrismicPageDataBodyPricingTable'
import PrismicPageDataBodyImageScroller from './wrappers/PrismicPageDataBodyImageScroller'
import PrismicPageDataBodyNonHubspotForm from './wrappers/PrismicPageDataBodyNonHubspotForm'
import PrismicPageDataBodyRoadsignCallout from './wrappers/PrismicPageDataBodyRoadsignCallout'
import PrismicPageDataBodyAnchor from './wrappers/PrismicPageDataBodyAnchor'

import PrismicCaseStudyDataBodyImageWithBulletPoints from './wrappers/PrismicCaseStudyDataBodyImageWithBulletPoints'
import PrismicCaseStudyDataBodyText from './wrappers/PrismicCaseStudyDataBodyText'
import PrismicCaseStudyDataBodyImage from './wrappers/PrismicCaseStudyDataBodyImage'
import PrismicCaseStudyDataBodyVideo from './wrappers/PrismicCaseStudyDataBodyVideo'
import PrismicCaseStudyDataBodyTestimonials from './wrappers/PrismicCaseStudyDataBodyTestimonials'
import PrismicCaseStudyDataBodyRoadsignCallout from './wrappers/PrismicCaseStudyDataBodyRoadsignCallout'

import PrismicInsightDataBodyImage from './wrappers/PrismicInsightDataBodyImage'
import PrismicInsightDataBodyText from './wrappers/PrismicInsightDataBodyText'
import PrismicInsightDataBodyImageWithText from './wrappers/PrismicInsightDataBodyImageWithText'
import PrismicInsightDataBodyVideo from './wrappers/PrismicInsightDataBodyVideo'
import PrismicInsightDataBodyHeading from './wrappers/PrismicInsightDataBodyHeading'

const slices = {
  PrismicPageDataBodyHero,
  PrismicPageDataBodyText,
  PrismicPageDataBodyImageWithText,
  PrismicPageDataBodyVideo,
  PrismicPageDataBodyLogos,
  PrismicPageDataBodyTestimonials,
  PrismicPageDataBodyImage,
  PrismicPageDataBodyPerson,
  PrismicPageDataBodyCaseStudy,
  PrismicPageDataBodyInsights,
  PrismicPageDataBodyHubspotForm,
  PrismicPageDataBodyHeading,
  PrismicPageDataBodyLocations,
  PrismicPageDataBodyShadowBox,
  PrismicPageDataBodyIconText,
  PrismicPageDataBodyCallout,
  PrismicPageDataBodyFullBackgroundHero,
  PrismicPageDataBodyRawHtml,
  PrismicPageDataBodyPricingTable,
  PrismicPageDataBodyImageScroller,
  PrismicPageDataBodyNonHubspotForm,
  PrismicPageDataBodyRoadsignCallout,
  PrismicPageDataBodyAnchor,

  PrismicCaseStudyDataBodyImageWithBulletPoints,
  PrismicCaseStudyDataBodyText,
  PrismicCaseStudyDataBodyImage,
  PrismicCaseStudyDataBodyVideo,
  PrismicCaseStudyDataBodyTestimonials,
  PrismicCaseStudyDataBodyRoadsignCallout,

  PrismicInsightDataBodyImage,
  PrismicInsightDataBodyText,
  PrismicInsightDataBodyImageWithText,
  PrismicInsightDataBodyVideo,
  PrismicInsightDataBodyHeading,
}

export const getSlice = (sliceType) => {
  return slices?.[sliceType]
}
