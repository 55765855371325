import * as React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import styled from "styled-components"
 import {primaryStyles} from "../../atoms/Button/index"


declare global {
  interface Window {
    dataLayer: any
  }
}

export const StyledForm = styled.form`
  line-height: 150%;
  max-width: 700px;
  margin: 0 auto;

  input, select, textarea {
    display: block;
    width: 100%;
  }

  &&& label, .label {
    display: block;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 28px;
    line-height: 150%;
  }

  .required:after {
    content: "*";
    color: #ff4d44;
  }
`

const StyledSubmit = styled.input`
  ${primaryStyles}
  &&& {
    max-width: 370px;
    margin: 0 auto;
  }
`

const StyledMessage = styled.div`
  margin: 1rem 0;
  font-weight: bold;
  padding: 10px;
  color: #fff;

  &.success {
    background: #008264;
  }

  &.error {
    background: #820000;
  }
`

interface RenderProps {
  handleChange: (event: any) => void,
}

interface BaseFormProps {
  render: ({}: RenderProps) => void,
  title: string,
  action: string,
  name: string,
  redirect: string,
}

export default class BaseForm extends React.Component<BaseFormProps, {
  isSubmitting: boolean,
  recaptchaToken: string,
  message: object
}> {

  private recaptchaRef: any = React.createRef()
  private formRef: any = React.createRef()

  constructor(props: BaseFormProps) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      isSubmitting: false,
      recaptchaToken: "",
      message: {
        status: null,
        body: ""
      }
    }
  }

  public handleChange = (event: any) => {
    // this.setState({ [event.target.name]: event.target.value })
  }

  public async verifyRecaptcha() {
    this.setState({ isSubmitting: true })
    const token = await this.recaptchaRef.current.executeAsync()
    this.handleSubmit(token)
  }

  public async handleSubmit(token) {
    const { redirect } = this.props
    const formRef = this.formRef

    this.setState({
      isSubmitting: true,
      message: {
        status: null,
        body: ""
      }
    })

    const formData = new FormData(formRef)
    formData.append("g-recaptcha-response", token)

    // Allows us to override the default save local function
    // this.saveLocal(formData)

    const me = this

    function submitForm(onComplete?: () => void) {

      fetch(formRef.getAttribute("action"), {
        method: "POST",
        body: formData,
        headers: {
          "Accept": "application/json"
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.success) {
            me.setState({
              message: {
                status: "error",
                body: "There was a problem submitting your request. Please contact us at info@weareroadmap.com"
              }
            })
          } else {
            me.setState({
              message: {
                status: "success",
                body: "We have received your request. We'll be in touch with you shortly."
              }
            })
            formRef.reset()
          }
        })
        .catch(error => {
          me.setState({
            message: {
              status: "error",
              body: "There was a problem submitting your request. Please contact us at info@weareroadmap.com"
            }
          })
        })
        .finally(() => onComplete ? onComplete() : null)
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      "event": "formCompleted",
      "formName": this.props.name
    })

    submitForm(() => this.setState({ isSubmitting: false }))
  }

  public render() {
    const { title = null, action, name, render } = this.props
    const { isSubmitting, message } = this.state
    return (
      <StyledForm
        action={action}
        name={name}
        method="POST"
        enctype={"multipart/form-data"}
        onSubmit={(e) => {
          e.preventDefault()
          this.verifyRecaptcha(e)
        }}
        ref={ref => this.formRef = ref}
      >
        <p style={{ display: "none" }}>
          <label>Don't fill this out if you're human: <input name="bot-field" /></label>
        </p>

        {title &&
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <h2>{title}</h2>
          </div>
        }

        {render({ handleChange: this.handleChange })}

        {message.status &&
          <StyledMessage className={message.status}>{message.body}</StyledMessage>
        }
        <div style={{ textAlign: "center" }}>
          <StyledSubmit
            inputAtts={{ value: isSubmitting ? "Please wait..." : "Submit" }}
            block={false}
            disabled={isSubmitting}
            type={"submit"}
          />
        </div>

        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"
        />

      </StyledForm>
    )
  }
}
