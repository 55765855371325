import * as React from "react"
import Container from "../../atoms/Container"
import ImageScroller from "../../organisms/ImageScroller"

const ImageScrollerSection = ({ title, images, autoScroll = false, speed = 0.8 }) => (
  <Container fluid={false}>
    <div style={{ marginBottom: 40 }}>
      {title}
    </div>
    <ImageScroller autoScroll={autoScroll} images={images} speed={speed} />
  </Container>
)

export default ImageScrollerSection
