import * as React from "react"
import { graphql } from "gatsby"
import ImageSection from "../../components/sections/ImageSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyImage = ({ primary }) => {
  const { image = null, width = "full" } = primary

  return (
    <ImageSection image={normalizeImageProps(image)} width={width} />
  )
}
export default PrismicPageDataBodyImage

export const query = graphql`
    fragment Image on PrismicPageDataBodyImage{
        slice_type
        slice_label
        __typename 
        primary{
            width
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 1440)
            }
        }
    }
`
