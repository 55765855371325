import { Composition, query } from "atomic-layout"
import * as React from "react"
import Container from "../../atoms/Container"
import Button from "../../atoms/Button"
import styled from "styled-components"

interface ArticleInfo {
  title: string,
  date?: string,
  url: string,
}

interface PrevNextProps {
  previous?: ArticleInfo,
  next?: ArticleInfo,
}

const StyledNext = styled.div`
  @media ${query({from: 'md'})}{
    text-align: right;
  }
`

const StyledPrevious = styled.div``

const options = { year: "numeric", month: "long", day: "numeric" }

const PrevNext = ({previous = null, next = null}: PrevNextProps) => (
    <Container>
      <Composition
        areas={`
              previous
              next
            `}
        areasMd={`previous next`}
        gapCol={"8rem"}
        gapRow={"4rem"}
        templateCols={"1fr"}
        templateRows={"auto"}
        templateColsMd={"1fr 1fr"}
        templateRowsMd={"1fr"}
      >
        {(Areas) => (
          <>
            {!!previous?.url &&
            <Areas.Previous as={StyledPrevious}>
              <p style={{ marginTop: 0 }}>
                <strong>{previous?.title}</strong>
                {previous?.date &&
                <span>
                  <br />
                  {(new Date(previous?.date)).toLocaleDateString("en-US", options)}
                </span>
                }
              </p>
              <Button to={previous?.url} secondary>Previous</Button>
            </Areas.Previous>
            }
            {!!next?.url &&
            <Areas.Next as={StyledNext}>
              <p style={{ marginTop: 0 }}>
                <strong>{next?.title}</strong>
                {next?.date &&
                <span>
                  <br />
                  {(new Date(next?.date)).toLocaleDateString("en-US", options)}
                </span>
                }
              </p>
              <Button to={next?.url} secondary>Next</Button>

            </Areas.Next>
            }
          </>
        )}
      </Composition>
    </Container>
  )

export default PrevNext
