import * as React from "react"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"
import Container from "../../atoms/Container"
import Offset from "../../atoms/Offset"
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

interface VideoProps {
  url?: string,
  small?: boolean,
  video?: {
    url: string,
  }
}

const StyledWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  ${({ theme, isYoutube }) => isYoutube && theme.shadow}
`

export const videoId = (url: string) => {
  if (!url) {
    return null
  }

  var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
  var match = url.match(regExp)

  if (match && match[1].length == 11) {
    return match[1]
  }
}

const ratioToPercent = (ratio: string) => {
  const [w, h] = ratio.split(":").map((num) => Number(num))
  return `${(h / w) * 100}%`
}

const StyledVideo = styled.video`
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`

const VideoSection = (props: VideoProps) => {
  const { url = null, small = false, video = null } = props

  const { ref, width = 1, height = 1 } = useResizeObserver()
  const id = videoId(url)
  const paddingBottom = ratioToPercent("16:9")

  if (!video?.url && !id) {
    return null
  }

  const size = video?.url ? {fluid: true} : {small};

  return (
    <Container innerProps={{ style: { marginBottom: "calc(var(--section-spacing) + var(--backdrop-offset))" } }} {...size}>
      <Offset width={width} height={height} direction={"right"} />
      <StyledWrapper style={{ paddingBottom }} isYoutube={!!url}>
        {video?.url &&
          <StyledVideo className="fullscreen"
                       autoPlay
                       muted
                       loop
                       style={{ objectFit: "cover", width: "100%"}}
          >
            <source src={video?.url} type="video/mp4;" />
          </StyledVideo>
        }
        {id &&
          <LiteYouTubeEmbed
            title={'video'}
            ref={ref}
            id={id}
            params={"rel=0&modestbranding=1"}
            poster={"maxresdefault"}
          />
        }
      </StyledWrapper>
    </Container>
  )
}

export default VideoSection
