import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import { ReactNode } from "react"
import Button from "../components/atoms/Button"
import Navigation from "../components/organisms/Navigation"

export function PrismicNavigation({ lang = "" }) {
  function isLinkActive(url: string, hasSubmenu: boolean) {
    if(typeof window !== 'undefined'){
      const path = url?.replace('https://www.weareroadmap.com/', '/');

      const currentPath = window.location.pathname + window.location.search;

      if(currentPath === '/') {
        return false
      }

      if(hasSubmenu){
        return path?.includes(window.location.pathname);
      } else {
        return currentPath === path;
      }
    }

    return false;
  }

  function createLink(linkData: any, hasSub: boolean = false, isActive = false): ReactNode {
    const { label, link, style } = linkData
    const isLink = style !== 'Button';
    const url = link?.url?.replace('https://www.weareroadmap.com/', '/');
    const localUrl = hasSub ? '#' : url;

    return (
      <Button
        href={localUrl}
        isLink={isLink}
        secondary={!isLink}
        className={[!isLink ? 'btn' : '', isActive ? 'active' : '', hasSub ? 'caret' : '', !localUrl ? 'label' : '']}
      >
        {label}
      </Button>
    )
  }

  return (
    <StaticQuery
      query={query}
      render={({ prismicMainMenu }) => {
        const { data } = prismicMainMenu

        const links = data.body.reduce((prev, menuItem: any, index: number) => {
          const { primary, items = [] } = menuItem

          const hasSubmenu = !!items.length;
          const submenuSelected = items.filter((item: any) => isLinkActive(item?.link?.url, true))?.length > 0;
          const selected = isLinkActive(primary?.url, !!items.length) || submenuSelected;

          let menu = {
            link: createLink(primary, hasSubmenu, selected),
            sub: items.map((link: any) => {
              return { link: createLink(link, false, isLinkActive(link?.link.url, false)), sub: [] } // these menus only go one level deep
            })
          }

          prev.push(menu)
          return prev
        }, [])

        return <Navigation menuItems={links} />
      }}
    />)
}

export const query = graphql`
    query navQuery{
        prismicMainMenu {
            data {
                body {
                    ... on PrismicMainMenuDataBodyMenuItem{
                        items{
                            label
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                        primary {
                            label
                            style
                            link {
                                lang
                                link_type
                                isBroken
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`
