import * as React from "react"
import {css} from "styled-components"

export const theme = {
    primaryColor: '#008264',
    secondaryColor: '#99CDC1',
    tertiaryColor: '#EAAA00',
    shadow: css`filter: drop-shadow(2px 10px 30px rgba(50, 34, 37, 0.25));`,
    modularTypeScale: {
        default: 1.2,
        md: 1.2,
        lg: 1.25,
    }, // https://type-scale.com
    breakpoints: { // min widths
        // xxl: 1920,
        xl: 1200,
        lg: 992,
        md: 768,
        sm: 576,
        xs: 575
    },
}

export default theme
