import { getImage } from "gatsby-plugin-image"

export const normalizeImageProps = (image) => {
  // if(Array.isArray(image)){
  //   return image.map(i => i?.localFile?.childImageSharp || i)
  // }
  return {
    // ...image,
    image: getImage(image),
    alt: image?.alt ? image.alt : "",
  }
}
