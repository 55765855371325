import { Composition, useMediaQuery, useResponsiveQuery } from "atomic-layout"
import { GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import Image from "../Image"
import {gap} from "../../../utils/spacing"
import styled from "styled-components"

export interface PersonProps {
  image?: GatsbyImageProps,
  name?: string,
  title?: string,
  text?: React.ReactNode,
  imagePosition?: "Left" | "Right",
}

const StyledTitle = styled.div`
  font-size: 28px;
  line-height: 140%;
`

const areas = `
image
text
`

const propsMd = {
  Left: {
    areasMd: `image gap text`,
    templateColsMd: '4fr 1fr 7fr',
  },
  Right: {
    areasMd: `text gap image`,
    templateColsMd: '7fr 1fr 4fr',
  }
}

const Person = ({name, title, text, imagePosition, image}: PersonProps) => {
  const isLargeScreen = useResponsiveQuery({ from: 'md' })
  
  return (
    <Composition 
      areas={areas}
      templateCols={'1fr'}
      templateRows={'auto'}
      {...propsMd[imagePosition]}
      templateRowsMd={'auto'}
      gap={gap}
    >
      {(Areas) => (
        <>
          <Areas.Image>
            {image.image &&
              <Image showOffset={isLargeScreen} direction={imagePosition.toLowerCase()} gatsbyImageProps={image} />
            }
          </Areas.Image>
          
          <Areas.Gap />
          
          <Areas.Text>
            <h3 style={{marginBottom: '0.5rem'}}>{name}</h3>
            <StyledTitle>{title}</StyledTitle>
            {text}
          </Areas.Text>
        </>
      )}
    </Composition>
  )
}

export default Person
