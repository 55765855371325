import { PrismicRichText } from "@prismicio/react"
import { graphql } from "gatsby"
import * as React from "react"
import TestimonialSection from "../../components/sections/TestimonialSection"

const PrismicCaseStudyDataBodyTestimonials = ({ items }) => {
  const testimonials = items.map(({ text = {}, name, person_title }) => {
    return {
      text: <PrismicRichText field={text?.richText} />,
      name,
      title: person_title
    }
  })

  return (
    <TestimonialSection testimonials={testimonials} />
  )
}

export default PrismicCaseStudyDataBodyTestimonials

export const query = graphql`
    fragment CaseStudyTestimonials on PrismicCaseStudyDataBodyTestimonials{
        slice_type
        slice_label
        __typename
        items{
            text{
                html
                richText
            }
            name
            person_title
        }
    }
`
