import { graphql } from "gatsby"
import parse from "html-react-parser"
import * as React from "react"
import { PricingTableColumn } from "../../components/organisms/PricingTable"
import PricingTableSection from "../../components/sections/PricingTableSection"

const PrismicPageDataBodyPricingTable = ({ primary, items }) => {
  const {
    title1 = null,
    disclaimer = null
  } = primary

  const columns: PricingTableColumn[] = items.map(({
                                                     title1 = null,
                                                     intro_text1 = null,
                                                     point_1 = null,
                                                     point_2 = null,
                                                     point_3 = null,
                                                     price = null
                                                   }) => {
      const points = [point_1, point_2, point_3].filter(point => point)
      return {
        title: title1,
        price,
        introText: intro_text1,
        points
      }
    }
  )

  return (
    <PricingTableSection
      title={parse(title1?.html)}
      columns={columns}
      disclaimer={parse(disclaimer?.html)}
    />
  )
}
export default PrismicPageDataBodyPricingTable

export const query = graphql`
    fragment PricingTable on PrismicPageDataBodyPricingTable{
        id
        slice_label
        slice_type
        __typename
        items {
            intro_text1
            point_1
            point_2
            point_3
            price
            title1
        }
        primary {
            disclaimer {
                html
                text
            }
            title1 {
                html
                text
            }
        }
    }
`
