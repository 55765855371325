import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { normalizeImageProps } from "../../utils/image"
import ImageScrollerSection from "../../components/sections/ImageScrollerSection"

const PrismicPageDataBodyLogos = ({ primary, items }) => {
  const { title1 = {}, auto_scroll = false } = primary

  return (
    <ImageScrollerSection autoScroll={auto_scroll} title={parse(title1?.html)} images={items.map((item) => normalizeImageProps(item.image))} />
  )
}
export default PrismicPageDataBodyLogos

export const query = graphql`
    fragment ImageScroller on PrismicPageDataBodyImageScroller{
        slice_type
        slice_label
        __typename
        primary{
            title1{
                html
            }
            auto_scroll
        }
        items{
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 287)
            }
        }
    }
`
