import { Box } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button"
import Container, { maxWidths } from "../../atoms/Container"

const StyledCallout = styled(Box)`
  padding: 80px 0;
`

const Callout = ({
                   heading = null,
                   text = null,
                   primaryButtonText = "",
                   primaryButtonUrl = {},
                   secondaryButtonText = "",
                   secondaryButtonUrl = {}
                 }) =>
  (
    <Container containerProps={{ style: { backgroundColor: "#f4f4f4" } }}>
      <StyledCallout
        {...maxWidths({ fluid: false, small: true })}
      >
        {heading}
        {text}

        {(primaryButtonText || secondaryButtonText) &&
        <Box 
          flex={true} 
          alignItems={"start"} 
          alignItemsMd={"center"} 
          flexDirection={"column"} 
          flexDirectionMd={"row"}
        >
          {primaryButtonText &&
          <Box
            marginRight={0}
            marginRightMd={gap}
            marginBottom={"2.125rem"}
            marginBottomMd={0}
          >
            <Button to={primaryButtonUrl?.url} primary>{primaryButtonText}</Button>
          </Box>

          }
          {secondaryButtonText &&
          <Box>
            <Button to={secondaryButtonUrl?.url} secondary style={{
              fontSize: 18,
              whiteSpace: "nowrap"
            }}>{secondaryButtonText}</Button>
          </Box>
          }
        </Box>
        }
      </StyledCallout>
    </Container>
  )

export default Callout
