import { Composition } from "atomic-layout"
import { graphql } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import Container from "../../components/atoms/Container"
import { gap } from "../../utils/spacing"
import Button from "../../components/atoms/Button"
import { DOMParser } from 'xmldom'

const StyledIframe = styled.iframe`
  margin-bottom: 40px;
  border: none;
`

const StyledAddress = styled.div`
  font-size: 23px;
  line-height: 150%;
  margin-bottom: 1.5rem;
`

const PrismicPageDataBodyLocations = ({ primary, items }) => {
  const { columns } = primary

  const cols = parseInt(columns.replace(" Column", ""))

  return (
    <Container>
      <Composition
        gap={'var(--section-spacing)'}
        gapLg={gap}
        templateCols={`1fr`}
        templateColsLg={`repeat(${cols}, 1fr)`}
      >
        {items.map((item) => {
          const { address = null, embed_code = null, phone = null, name = null } = item

          let src = null;
          if(embed_code){
            const parser = new DOMParser()
            const parsedIframe = parser.parseFromString(embed_code, "text/html")
            const iFrame = parsedIframe.getElementsByTagName("iframe")
            // Read URL:
            src = iFrame?.[0]?.getAttribute('src')
          }

          return (
            <div key={name}>
              {src &&
                <StyledIframe src={src} width={"100%"} height={264} />
              }
              <h3>{name}</h3>
              <StyledAddress dangerouslySetInnerHTML={{ __html: address.replace(/\n/g, "<br />") }}></StyledAddress>
              <Button to={'tel:' + phone.replace(/[^0-9]+/g, '')} secondary>{phone}</Button>
            </div>
          )
        })}
      </Composition>
    </Container>
  )
}
export default PrismicPageDataBodyLocations

export const query = graphql`
    fragment Locations on PrismicPageDataBodyLocations {
        slice_label
        slice_type
        __typename
        id
        items {
            address
            embed_code
            phone
            name
        }
        primary {
            columns
        }
    }
`
