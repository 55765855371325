import * as React from "react"
import { graphql } from "gatsby"
import ImageWithText, { ImageWithTextProps } from "../../components/organisms/ImageWithText"
import { normalizeImageProps } from "../../utils/image"
import parse from "html-react-parser"
import ImageWithTextSection from "../../components/sections/ImageWithTextSection"

const PrismicInsightDataBodyImageWithText = ({ primary, items }) => {
  const { cols, background_color } = primary

  const imageWithTextItems = items.map((item): ImageWithTextProps  => {
    const { image, button_text, button_url, heading, image_position, image_text, text, logo } = item
    const imageProps = normalizeImageProps(image)
    const logoProps = normalizeImageProps(logo)

    return {
      buttonText: button_text,
      buttonUrl: button_url,
      image: imageProps,
      text: parse(text?.html),
      heading: parse(heading?.html),
      imagePosition: image_position,
      logo: logoProps
    }
  })

  return (
    <ImageWithTextSection
      items={imageWithTextItems}
      backgroundColor={background_color}
      columns={parseInt(cols.replace(' Column', ''))}
    />
  )
}
export default PrismicInsightDataBodyImageWithText

export const query = graphql`
    fragment InsightImageWithText on PrismicInsightDataBodyImageWithText {
        slice_label
        slice_type
        __typename
        id
        primary{
            cols
            background_color
        }
        items {
            button_text
            button_url {
                url
                target
            }
            heading {
                html
            }
            image{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 1200)
            }
            logo{
                alt
                gatsbyImageData(layout: CONSTRAINED, width: 500, imgixParams: {sat: -100})
            }
            image_position
            image_text
            text {
                html
            }
        }
    }
`
