import React, { useRef } from "react"
import Container from "../../atoms/Container"
import styled from "styled-components"
import { query, useResponsiveQuery } from "atomic-layout"
import Button from "../../atoms/Button"
import Background from "../../molecules/Background"

const StyledContainer = styled(Container)`
  //overflow: hidden;
`

const StyledText = styled.div`
  color: #000;
  max-width: 520px;
  @media ${query({ from: "md" })} {
    color: #fff;
  }
`

const StyledBackground = styled(Background)`
  display: none;
  @media ${query({ from: "md" })} {
    display: block;
  }
`

const FullBackgroundHeroSection = ({
                                     heading = null,
                                     image = {},
                                     video = {},
                                     buttonUrl,
                                     buttonText,
                                     text = {}
                                   }) => {
  const contentRef = useRef(null)
  const showVideo = useResponsiveQuery({ from: "md" })

  const background = <StyledBackground image={image} overlay={"rgba(0,0,0,0.45)"}>
    {showVideo && video?.url &&
      <video className="fullscreen" autoPlay muted loop style={{ objectFit: "cover", width: "100%", height: "100%" }}>
        <source src={video?.url} type="video/mp4;" />
      </video>
    }
  </StyledBackground>

  return (
    <StyledContainer background={background} innerProps={{ style: {} }}>
      <div ref={contentRef} style={{ position: "relative" }}>
        <StyledText>
          {heading}
          {text}
          <Button to={buttonUrl?.url} target={buttonUrl?.target} primary>{buttonText}</Button>
        </StyledText>
      </div>
    </StyledContainer>
  )
}

export default FullBackgroundHeroSection
