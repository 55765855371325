import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import * as React from "react"
import { render } from "react-dom"
import styled from "styled-components"
import Container from "../../atoms/Container"
import Testimonial, { TestimonialProps } from "../../organisms/Testimonial"

interface TestimonialSectionProps {
  testimonials: TestimonialProps[]
}

const StyledDots = styled.div`
  .carousel__dot-group {
    text-align: center;
  }

  .carousel__dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #B2AAAB;
    background-color: transparent;
    margin-right: 24px;
    margin-top: 80px;

    &:last-child {
      margin-right: 0;
    }

    &.carousel__dot--selected {
      background-color: #99CDC1;
    }
  }
`

const TestimonialSection = ({ testimonials }: TestimonialSectionProps) => {

  function renderMultiple(){
    return (
      <CarouselProvider
        totalSlides={testimonials.length}
        isIntrinsicHeight={true}
        naturalSlideHeight={400}
        naturalSlideWidth={700}
      >
        <Slider>
          {testimonials.map(({ text, name, title }, index) =>
            <Slide index={index} key={name + index}>
              <Testimonial name={name} text={text} title={title} />
            </Slide>)}
        </Slider>
        {testimonials.length > 1 &&
        <StyledDots>
          <DotGroup />
        </StyledDots>
        }
      </CarouselProvider>
    )
  }
  
  return (
    <Container small>
      {testimonials.length > 1 && renderMultiple()}
      {testimonials.length === 1 && <Testimonial name={testimonials[0].name} text={testimonials[0].text} title={testimonials[0].title} />}
    </Container>
  )
}

export default TestimonialSection
