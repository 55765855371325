/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { defaultOptions } from "@atomic-layout/core"
import Layout from "atomic-layout"
import * as React from "react"
import { componentResolverFromMap, PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"
import Page from "./src/templates/page"
import Insight from "./src/templates/insight"
import CaseStudy from "./src/templates/case_study"
import { PrismicProvider } from "@prismicio/react"
import Link from "./src/components/atoms/Link"

const linkResolver = require("./src/utils/link-resolver").linkResolver

// You can delete this file if you're not using it
export const onClientEntry = () => {
  let options = defaultOptions

  // Example of how to create a wider xl max width
  // options.breakpoints.xl.minWidth = '1600px'

  Layout.configure(options)
}

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}
    externalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}
  >
    <PrismicPreviewProvider
      repositoryConfigs={[{
        repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: Page,
          insight: Insight,
          case_study: CaseStudy
        })
      }]}
    >{element}</PrismicPreviewProvider>
  </PrismicProvider>
)

export const shouldUpdateScroll = ({
                                     routerProps: { location },
                                     getSavedScrollPosition
                                   }) => {

  const currentPosition = getSavedScrollPosition(location)

  if (location?.state?.noScroll) {
    return false
  }

  window.scrollTo(...(currentPosition || [0, 0]))
}
