import * as React from 'react'
import Video from "../../components/sections/VideoSection"
import { graphql } from "gatsby"

const PrismicPageDataBodyVideo = ({ primary }) => {

  return (
    <Video url={primary?.url} />
  )
}
export default PrismicPageDataBodyVideo

export const query = graphql`
    fragment Video on PrismicPageDataBodyVideo {
        slice_label
        slice_type
        __typename
        primary{
            url
        }
    }
`
