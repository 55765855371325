import * as React from "react"
import styled from "styled-components"

export interface TestimonialProps {
  name?: string,
  title?: string,
  text?: React.ReactNode
}

const StyledTestimonial = styled.div`
  text-align: center;
`

const StyledText = styled.div`
  margin-bottom: 40px;
  
  p{
    &:before{
      content: "“";
    }

    &:after{
      content: "”";
    }
  }
  
`

const StyledName = styled.div`
  font-size: 18px;
  line-height: 155%;
`

const StyledTitle = styled.div`
  font-size: 18px;
  line-height: 155%;
  color: #B2AAAB;
`

const Testimonial = ({text = null, title = "", name = ""}: TestimonialProps) => (
  <StyledTestimonial>
    <StyledText>{text}</StyledText>
    <StyledName>{name}</StyledName>
    <StyledTitle>{title}</StyledTitle>
  </StyledTestimonial>
)

export default Testimonial
