import { Box, Composition, query } from "atomic-layout"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Button from "../../atoms/Button"
import Container, { maxWidths } from "../../atoms/Container"

const StyledOuterCallout = styled.div`
  background-color: var(--primary-color);
  border-radius: 30px;
  padding: clamp(10px, 1.6vw, 26px);
`

const StyledInnerCallout = styled.div`
  border-radius: 30px;
  padding: clamp(24px, 3.472vw, 50px);
  border: 5px solid #FFFFFF;
  color: #fff;
`

const StyledComposition = styled(Composition)`
  align-items: center;
`

const StyledImage = styled.div`
  //padding: 40px;
  text-align: center;
`

const StyledText = styled.div`
  text-align: center;
  @media ${query({from: "lg"})}{
      text-align: left;
  }

  @media ${query({from: "xl"})}{
    text-align: center;
  }
`

type RoadsignCalloutSectionProps = {
  heading: string,
  text: React.ReactNode,
  buttonText?: string,
  buttonUrl?: buttonUrl,
  image?: GatsbyImageProps
}

const RoadsignCallout = ({
                           heading = null,
                           text = null,
                           buttonText = "",
                           buttonUrl = null,
                           image = null
                         }: RoadsignCalloutSectionProps) =>
  (
    <Container>
      <StyledOuterCallout
        {...maxWidths({ fluid: true, small: true })}
      >
        <StyledInnerCallout>
          <StyledComposition
            areas={`
            image 
            content 
            gap
            `}
            areasLg={`image content`}
            templateColsLg={"1fr 3fr"}
            areasXl={`image content gap`}
            templateColsXl={"1fr 3fr 1fr"}
            gap={32}
          >
            {(Areas) => (
              <>
                <Areas.Image>
                  <StyledImage>
                  {image &&
                  <GatsbyImage {...image} style={{maxHeight: '300px'}} objectFit={'contain'}/>
                  }
                  </StyledImage>
                </Areas.Image>
                <Areas.Content>
                  <StyledText>
                    <h2>{heading}</h2>
                  {text}
                  {buttonUrl?.url && buttonText &&
                    <Button to={buttonUrl?.url} target={buttonUrl?.target} inverted>{buttonText}</Button>}
                  </StyledText>
                </Areas.Content>
                <Areas.Gap></Areas.Gap>
              </>
            )}
          </StyledComposition>
        </StyledInnerCallout>
      </StyledOuterCallout>
    </Container>
  )

export default RoadsignCallout
