import * as React from "react"
import Container from "../../atoms/Container"
import Person, { PersonProps } from "../../organisms/Person"


const PersonSection = ({ person }: { person: PersonProps }) => (
  <Container>
    <Person {...person} />
  </Container>
)

export default PersonSection
