import * as React from 'react'
import Video from "../../components/sections/VideoSection"
import { graphql } from "gatsby"

const PrismicCaseStudyDataBodyVideo = ({primary}) =>
  <Video url={primary?.url} video={primary?.video} />
export default PrismicCaseStudyDataBodyVideo

export const query = graphql`
    fragment CaseStudyVideo on PrismicCaseStudyDataBodyVideo {
        slice_label
        slice_type
        __typename
        primary{
            url
            video{
                url
            }
        }
    }
`
