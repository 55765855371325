import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import FullBackgroundHeroSection from "../../components/sections/FullBackgroundHeroSection"
import { normalizeImageProps } from "../../utils/image"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyFullBackgroundHero = ({ primary }) => {
  const {
    button_text = null,
    button_url = null,
    heading = {},
    text = {},
    image = {},
    video = {}
  } = primary

  return (
    <FullBackgroundHeroSection
      heading={parse(heading?.html)}
      text={<PrismicRichText field={text?.richText} />}
      buttonText={button_text}
      buttonUrl={button_url}
      image={normalizeImageProps(image)}
      video={video}
    />
  )
}
export default PrismicPageDataBodyFullBackgroundHero

export const query = graphql`
    fragment FullBackgroundHero on PrismicPageDataBodyFullBackgroundHero{
        slice_type
        slice_label
        __typename
        primary{
            button_text
            button_url {
                url
                target
            }
            video {
                url
            }
            text{
                html
                richText
            }
            heading{
                html
                text
            }
            image{
                alt
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`
