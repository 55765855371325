import * as React from "react"
import { graphql } from "gatsby"
import IconTextSection from "../../components/sections/IconTextSection"
import { normalizeImageProps } from "../../utils/image"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyIconText = ({ items, id, primary }) => {
  const sections = items.map(({ text = {}, image }) => ({
    text: <PrismicRichText field={text?.richText} />,
    image: normalizeImageProps(image),
    id
  }))
  return <IconTextSection items={sections} />
}
export default PrismicPageDataBodyIconText

export const query = graphql`
    fragment IconText on PrismicPageDataBodyIconText{
        slice_type
        slice_label
        __typename
        id
        items{
            text{
                html
                richText
            }
            image{
                alt
                gatsbyImageData(layout: FIXED, width: 129, height: 129)
            }
        }
    }
`
