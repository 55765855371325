import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import useResizeObserver from "use-resize-observer"
import Offset from "../../atoms/Offset"

interface ImageProps {
  style?: React.CSSProperties,
  className?: string,
  showOffset?: boolean,
  direction?: 'left' | 'right',
  gatsbyImageProps: GatsbyImageProps,
} 

const StyledImage = styled.div`
  ${({ showShadow, theme }) => showShadow && theme.shadow}
  overflow: visible;
`

const Image = ({ 
                 style = {}, 
                 className = "", 
                 showOffset = true, 
                 direction = "left", 
                 gatsbyImageProps = null
}: ImageProps) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()

  return (
    <div style={{ position: "relative", overflow: "visible", ...style }} className={className}>
      {showOffset &&
      <Offset width={width} height={height} direction={direction} />
      }
      <StyledImage showShadow={showOffset} ref={ref}>
        <GatsbyImage {...gatsbyImageProps} />
      </StyledImage>
    </div>
  )
}

export default Image
