import { Composition } from "atomic-layout"
import * as React from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button"

export type PricingTableColumn = {
  title?: string,
  price?: string,
  introText?: string,
  points?: string[],
}

export type PricingTableProps = {
  title?: React.Node,
  columns: PricingTableColumn[],
  disclaimer?: React.ReactNode,
}

const StyledCol = styled.div`
  text-align: center;
  padding: ${gap};
  border: 2px solid #F3F3F3;
`

const StyledPoint = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #262626;

  border-top: 2px solid #F3F3F3;
  padding: 25px 0;
`

const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 35px;
  line-height: 120%;
  text-align: center;
  color: #008264;
`

const StyledPricing = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #008264;
`

const StyledIntroText = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #262626;
`

const StyledDisclaimer = styled.div`
  &, p {
    font-size: 14px;
    line-height: 150%;
  }
`

const PricingTable = ({ title = null, columns = [], disclaimer = null }: PricingTableProps) => {
  return (
    <div>
      <div style={{textAlign: 'center'}}>{title}</div>
      <Composition
        templateCols={"1fr"}
        templateColsLg={`repeat(${Math.min(columns.length, 3)}, 1fr)`}
        gap={`calc(${gap} * 2)`}
      >
        {columns.map(({ title, price, introText, points }) => (
          <StyledCol key={title}>
            <StyledTitle>{title}</StyledTitle>
            <StyledPricing>{price}</StyledPricing>
            <StyledIntroText>{introText}</StyledIntroText>

            {points.map((point) => (
              <StyledPoint>{point}</StyledPoint>
            ))}
            <Button to={"https://www.weareroadmap.com/book-call/"} primary>Talk to sales</Button>
          </StyledCol>
        ))}
      </Composition>
      <StyledDisclaimer>{disclaimer}</StyledDisclaimer>
    </div>
  )
}

export default PricingTable
