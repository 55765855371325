import * as React from 'react'
import Video from "../../components/sections/VideoSection"
import { graphql } from "gatsby"

const PrismicInsightDataBodyVideo = ({primary}) => {

  return (
    <Video url={primary?.url} small />
  )
}
export default PrismicInsightDataBodyVideo

export const query = graphql`
    fragment InsightVideo on PrismicInsightDataBodyVideo {
        slice_label
        slice_type
        __typename
        primary{
            url
        }
    }
`
