import * as React from "react"
import { graphql } from "gatsby"
import Hero from "../../components/sections/HeroSection"
import parse from "html-react-parser"
import { normalizeImageProps } from "../../utils/image"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyHero = ({ primary }) => {
  const {
    button_text = null,
    button_url = null,
    heading = {},
    text = {},
    image = {},
    testimonial_content = null,
    testimonial_name = null,
    testimonial_title = null
  } = primary

  return (
    <Hero
      heading={parse(heading?.html)}
      text={<PrismicRichText field={text?.richText} />}
      buttonText={button_text}
      buttonUrl={button_url}
      image={normalizeImageProps(image)}
      testimonialTitle={testimonial_title}
      testimonialName={testimonial_name}
      testimonialContent={testimonial_content}
    />
  )
}
export default PrismicPageDataBodyHero

export const query = graphql`
    fragment Hero on PrismicPageDataBodyHero{
        slice_type
        slice_label
        __typename
        __typename
        primary{
            button_text
            button_url {
                url
                target
            }
            text{
                html
                richText
            }
            heading{
                html
                text
            }
            image{
                alt
                gatsbyImageData(layout: FULL_WIDTH)
            }
            testimonial_content
            testimonial_name
            testimonial_title
        }
    }
`
