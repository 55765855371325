import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ImageDataLike } from "gatsby-plugin-image/dist/src/components/hooks"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { gap } from "../../../utils/spacing"
import Button from "../../atoms/Button"

type ImageScrollerProps = {
  image: ImageDataLike,
  link?: string,
}

const StyledScroller = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  ${({ autoScroll }) => autoScroll && `
  &::-webkit-scrollbar {
    display: none;
  }
  
  &{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  `}
`

const StyledImage = styled.div`
  margin-right: ${gap};
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
`

const ImageScroller = ({
                         images,
                         autoScroll = false,
                         speed = 0.8
                       }: { images: ImageScrollerProps[], autoScroll?: boolean, speed?: number }) => {
  const ref = useRef()
  const duplicate = useRef()

  useEffect(() => {
    if (autoScroll && ref?.current && duplicate?.current) {
      // const offsetLeft = ref.current.offsetLeft
      const offsetLeft = ref?.current?.getBoundingClientRect()?.x

      const scrollLeft = () => {

        if(typeof ref?.current?.scrollLeft !== "undefined") {
          const newPosition = ref?.current?.scrollLeft + speed
          const end = duplicate?.current?.getBoundingClientRect().x - offsetLeft
          ref.current.scrollLeft = end < 0 ? 0 : newPosition
        }


        window.requestAnimationFrame(scrollLeft)
      }
      window.requestAnimationFrame(scrollLeft)
    }

  }, [ref, duplicate])

  return (
    <StyledScroller ref={ref} autoScroll={autoScroll}>
      {images.map((item, index) => {
          const gatsbyImage = <GatsbyImage image={getImage(item.image)} alt={"image"} />
          if (item.link) {
            return <StyledImage key={"insta" + index}><Button target={"_blank"} to={item.link}>{gatsbyImage}</Button></StyledImage>
          }
          return <StyledImage key={"insta" + index}>{gatsbyImage}</StyledImage>
        }
      )}

      {autoScroll && images.map((item, index) => {
          const gatsbyImage = <GatsbyImage image={getImage(item.image)} alt={"image"} />
          if (item.link) {
            return <StyledImage ref={index === 0 ? duplicate : null} key={"insta2" + index}><Button target={"_blank"} to={item.link}>{gatsbyImage}</Button></StyledImage>
          }
          return <StyledImage ref={index === 0 ? duplicate : null} key={"insta2" + index}>{gatsbyImage}</StyledImage>
        }
      )}

    </StyledScroller>
  )
}

export default ImageScroller
