import * as React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import PersonSection from "../../components/sections/PersonSection"
import { normalizeImageProps } from "../../utils/image"

const PrismicPageDataBodyPerson = ({ primary }) => {
  const {
    image_position,
    person: {
      document: { data: { bio = {}, name = "", title = "", image } }
    }
  } = primary
  return (
    <PersonSection
      person={{
        name,
        title: title,
        text: parse(bio?.html),
        imagePosition: image_position,
        image: normalizeImageProps(image)
      }}
    />
  )
}
export default PrismicPageDataBodyPerson

export const query = graphql`
    fragment Person on PrismicPageDataBodyPerson{
        slice_type
        slice_label
        __typename 
        primary{
            person{
                document{
                    ... on PrismicPerson{
                        data{
                            name
                            title
                            bio{
                                html
                            }
                            image{
                                alt
                                gatsbyImageData(layout: CONSTRAINED, width: 900)
                            }
                        }
                    }
                }
            }

            image_position
        }
    }
`
