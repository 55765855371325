import * as React from "react"
import { graphql } from "gatsby"
import Forms from "../../components/sections/Forms"

const NonHubspotForm = ({ primary }) => {
  const { type = null } = primary

  return <Forms type={type} />
}

export const query = graphql`
  fragment NonHubspotForm on PrismicPageDataBodyNonHubspotForm {
    slice_type
    slice_label
    __typename
    primary{
      type
    }
  }
`

export default NonHubspotForm
