import * as React from "react"
import { useState } from "react"
import HubspotForm from "react-hubspot-form"
import styled, { createGlobalStyle } from "styled-components"
import { primaryStyles } from "../../atoms/Button"
import Container from "../../atoms/Container"

const StyledRecaptcha = styled.div`
  font-size: 12px;
  color: #bdbdbd;
  margin-top: 2rem;
  text-align: center;

  a, a:hover {
    color: #bdbdbd;
    text-decoration: underline;
  }
`

const GlobalStyles = createGlobalStyle`
  ul.multi-container {
    list-style: none;
    padding: 0;
    margin-bottom: var(--input-bottom-margin);

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    input {
      margin: 0;
      margin-right: 1rem;
      cursor: pointer;
    }
  }

  .hs-fieldtype-checkbox {
    margin: 1rem 0;
  }

  .hs-button.primary {
    ${primaryStyles}
  }

  .grecaptcha-badge {
    display: none;
  }

  .hs-error-msgs {
    font-size: 1rem;
    font-weight: normal;
    padding: 0;
    list-style: none;
    margin: 0.5rem 0;
    color: #ff4646;
  }

  .hs-form-field {
    margin-bottom: var(--input-bottom-margin);
  }
`

export default ({ portalId, formId }) => {
  const [showForm, setShowForm] = useState(false)

  return (
    <Container small>
      <GlobalStyles />
      <React.Fragment>
        <HubspotForm
          portalId={portalId}
          formId={formId}
          loading={<h3>Loading...</h3>}
        />
        <StyledRecaptcha>
          This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </StyledRecaptcha>
      </React.Fragment>
    </Container>
  )
}
