import * as React from "react"
import { graphql } from "gatsby"
import ShadowBoxSection from "../../components/sections/ShadowBoxSection"
import { normalizeImageProps } from "../../utils/image"
import { PrismicRichText } from "@prismicio/react"

const PrismicPageDataBodyShadowBox = ({ items, id, primary }) => {
  const boxes = items.map(({ text, image }) =>
    ({
      text: <PrismicRichText field={text.richText} />,
      image: normalizeImageProps(image),
      id
    }))
  return <ShadowBoxSection heading={<PrismicRichText field={primary.heading.richText} />} boxes={boxes} />
}
export default PrismicPageDataBodyShadowBox

export const query = graphql`
    fragment ShadowBox on PrismicPageDataBodyShadowBox{
        slice_type
        slice_label
        __typename
        id
        primary{
            heading{
                html
                richText
            }
        }
        items{
            text{
                html
                richText
            }
            image{
                alt
                gatsbyImageData(layout: FIXED, width: 118, height: 100)
            }
        }
    }
`
