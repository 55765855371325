import React from "react"
import styled, { css } from "styled-components"

const directionCss = {
  left: css`translateX(calc(var(--offset) * -1))
    translateY(var(--offset))`,
  right: css`translateX(var(--offset))
    translateY(var(--offset))`
}

const StyledOffset = styled.div`
  --offset: var(--backdrop-offset);
  position: absolute;
  background-color: ${({ color }) => color};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  transform: ${({ direction }) => directionCss[direction]};
`

const Offset = ({ width, height, color = "#ECEAEA", direction = "left" }) => {

  return (
    <StyledOffset
      direction={direction}
      height={height}
      width={width}
      color={color}
    />
  )
}

export default Offset
